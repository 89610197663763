html {
    font-family: Georgia, serif;
}

body {
    margin: 0;
    padding: 0;
}

html, main, header, section, section > header, footer, h1, nav {
    margin-inline-end: 0;
    margin-inline-start: 0;
    margin-block-end: 0;
    margin-block-start: 0;

}


/**/

html {
    background: lightgray;
}


.linkdiv, header {
    background-color: bisque;
}


footer  {
    background: whitesmoke;
}
/*
.linkdiv {
    background: lightgray;
}*/

nav {
    background: whitesmoke;
}


.linktext, footer {
    background-color: blanchedalmond;
}


/***/

.p-tabmenu {
    display: table-row;
    padding: 10px;
    margin: 10px;
}

.p-tabmenuitem {

    display: table-cell;
    padding: 0;
    margin: 5px;
    width: 20%;
    min-width: 150px;
    min-height: 50px;

    border: 1px dotted black;
}


nav {
    display: table;
    padding: 0;
    width: 100%;
    border: 3px dotted black;
}


/* */
.navdiv {
    border-bottom: 1px solid black;
}


.linksection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    overflow-wrap: anywhere;

}

.linkdiv {
    padding: 2%;
    margin: 1%;

    flex-basis: 48%;
    min-width: 200px;

}



.linktext {
    margin: 5% 0 0 0;
    padding: 3%;
    font-size: large;
    font-weight: bold;
    border: solid 1px black;

}



/**/


.linkdiv, .floatdiv, header, footer {
    border: 1px solid black;

}

main, aside, header, section, section > header, footer, nav, .floatdiv, .linkdiv  {
    border-radius: 0.2em;
}



footer, header {
    padding: 1%;
    margin: 1%;
}

.p-tabmenu-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0;
}


.navdiv, .floatdiv {
    background-color: white;
}

.floatdiv {
    padding: 1%;
    padding-top: 0;
    margin: 0.5%;
}

.navdiv {
    position: sticky;
    top: 0;
    padding-bottom: 1.25%;
    padding-top: 1.25%;
}



main {
    padding: 0;
}

aside {
    background: lightblue;
}

section {
    padding: 2%;
    margin: 0;
}

section > header {
    padding: 0;
}



header {
    display: flex;
    flex-direction: column;
}

footer {
    display: flex;
    justify-content: center;
    font-weight: bolder;
}




h1 {
    font-size: xxx-large;
    text-align: center;
    padding: 1%;
}


p {
    font-size: large;
    text-align: justify;

}


.python_kitty, .programmer_logo, .mathpic {
    margin-inline-end: 0;
    margin-inline-start: 0;
    margin-block-end: 0;
    margin-block-start: 0;


    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.header_holder {
    position: relative;
    padding: 0;
    margin: 0;
    height: 25vh;
    width: 100%;
}

.mathpic {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    background-image: url('./img/mathpic_1920x425.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.5;
    position: relative;
    height: 425px;
}


.programmer_logo {
    background-image: url('./img/programmer_logo.webp');
    opacity: 1.0;
    position: absolute;

    top: 12.5%;
    left: 25%;


    min-width: 175px;
    min-height: 175px;

    width: 50%;
    height: 75%;
}

.python_kitty {
    background-image: url('./img/python_kitty_256.webp');
    width: 100%;
    flex-direction: column;
    flex-basis: 1000px;
    min-height: 200px;
}

@media screen and (max-width: 500px) {
    h1 {
        font-size: large;
    }
    .linkheader {
        font-size: small;
    }
    footer {
        font-size: small;
    }
    p {
        font-size: small !important;
    }
    .linkdiv {
        flex-basis: 98%;
    }
    .programmer_logo {
        top: 12.5%;
        left: 0;

        min-width: 100%;
        min-height: 100px;
    }
}
